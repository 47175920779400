'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _plugin = require('@swup/plugin');

var _plugin2 = _interopRequireDefault(_plugin);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BodyClassPlugin = function (_Plugin) {
	_inherits(BodyClassPlugin, _Plugin);

	function BodyClassPlugin(options) {
		_classCallCheck(this, BodyClassPlugin);

		var _this = _possibleConstructorReturn(this, (BodyClassPlugin.__proto__ || Object.getPrototypeOf(BodyClassPlugin)).call(this));

		_this.name = 'BodyClassPlugin';


		var defaultOptions = {
			prefix: ''
		};

		_this.options = _extends({}, defaultOptions, options);
		return _this;
	}

	_createClass(BodyClassPlugin, [{
		key: 'mount',
		value: function mount() {
			var _this2 = this;

			this.swup.on('contentReplaced', function () {
				var page = _this2.swup.cache.getCurrentPage();

				// remove old classes
				document.body.className.split(' ').forEach(function (className) {
					if (_this2.isValidClassName(className)) {
						document.body.classList.remove(className);
					}
				});

				// add new classes
				if (page.pageClass !== '') {
					page.pageClass.split(' ').forEach(function (className) {
						if (_this2.isValidClassName(className)) {
							document.body.classList.add(className);
						}
					});
				}
			});
		}
	}, {
		key: 'isValidClassName',
		value: function isValidClassName(className) {
			return className !== '' && className.indexOf(this.options.prefix) !== -1;
		}
	}]);

	return BodyClassPlugin;
}(_plugin2.default);

exports.default = BodyClassPlugin;
var device = require('current-device');

import $ from 'jquery/dist/jquery.slim';
window.$ = $;
window.jQuery = $;

import LazyLoad from 'vanilla-lazyload';
window.LazyLoad = LazyLoad;

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
window.gsap = gsap;

var Swup = require( 'swup' ).default;
window.Swup = Swup;

import SwupJsPlugin  from '@swup/js-plugin';
window.SwupJsPlugin = SwupJsPlugin;

import SwupBodyClassPlugin  from '@swup/body-class-plugin';
window.SwupBodyClassPlugin = SwupBodyClassPlugin;

var Swiper = require('swiper');
window.Swiper = Swiper;

import SimpleLightbox from 'simple-lightbox';
SimpleLightbox.registerAsJqueryPlugin($);